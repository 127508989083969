/*
 * Cartzilla | Bootstrap E-Commerce Template
 * Copyright 2022 Createx Studio
 * Theme styles
 */

// User variables
@import 'user-variables';

// Configuration & utilities
@import 'variables';
@import 'mixins';
@import 'utilities';
@import 'carousel-swipe';

// Bootstrap
@import './node_modules/bootstrap/scss/bootstrap';

// Layout & components
@import 'reboot';
@import 'components';

// User custom styles
@import 'user';
