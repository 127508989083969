@import "variables";

.slick-dots {
    margin-top: 5px;
    bottom: 0px;
}

.slick-dots li.slick-active button:before {
    content: '';
    display: inline-block;
    height: 0.3125rem;
    margin: 0 0.375rem;
    padding: 0;
    margin-top: 30px;
    transition: width .2s ease-in-out, background-color .2s ease-in-out;
    border: 0;
    border-radius: 0.15625rem;
    width: 1.25rem;
    background-color: $accent;
}

.slick-dots li button:before {
    content: '';
    display: inline-block;
    width: 0.3125rem;
    height: 0.3125rem;
    margin: 0 0.375rem;
    margin-top: 30px;
    padding: 0;
    transition: width .2s ease-in-out, background-color .2s ease-in-out;
    border: 0;
    border-radius: 0.15625rem;
    background-color: #1d2229;
}

.slick-prev {
    left: 25px;
}

.slick-next {
    right: 25px;
}
