/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }
body { margin: 0; }

@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone&display=swap");

// importacion local de font
@font-face {
    font-family: "intro-rust-base"; //titulos
    src:
    url("assets/fonts/IntroRust-Base.otf") format("opentype"),
}

@font-face {
    font-family: "intro-karma-bold"; //subtitulo, resaltado
    src:
    url("assets/fonts/Karma-SemiBold.ttf") format("truetype"),
}

@font-face {
    font-family: "intro-karma"; //subtitulo, resaltado
    src:
    url("assets/fonts/Karma-Regular.ttf") format("truetype"),
}

@font-face {
    font-family: "karma-bold"; //header
    src: url("assets/fonts/Karma-Bold.ttf") format("truetype")
}

@font-face {
    font-family: "intro-rustbook-base";
    src: url("assets/fonts/IntroRustBook-Base.otf") format("truetype")
}